import Debug from 'debug';

import React, { FC, useCallback, useEffect, useMemo } from 'react';
import dynamic from 'next/dynamic';

import { ActionItemsSection } from '~/app/components/ItemPage/ItemPageActions/ActionItems';
import ItemPageHeaderContent from '~/app/components/ItemPage/ItemPageHeaderContent';
import ItemPageHeroContent from '~/app/components/ItemPage/ItemPageHeroContent';
import deleteAlbumAction from '~/app/lib/store/albums/actions/deleteAlbum';
import ItemPageMetadata from '~/app/components/ItemPage/ItemPageMetadata';
import useFetchSessionUser from '~/app/lib/hooks/useFetchSessionUser';
import { useAppToast } from '~/app/components/NextApp/lib/CoreUi';
import { ItemPageProps } from '~/app/components/ItemPage/types';
import ArtistNames from '~/app/components/ItemPage/ArtistNames';
import useIsLargeScreen from '~/app/lib/hooks/useIsLargeScreen';
import useSelectArtists from '~/app/lib/hooks/useSelectArtists';
import useSelectAlbum from '~/app/lib/hooks/useSelectAlbum';
import { PageTrackerContext } from '~/app/components/Page';
import LazyComponent from '~/app/components/LazyComponent';
import { useDispatch } from '~/app/lib/store/redux';
import { useAppRouter } from '~/app/lib/router2';
import ItemPage from '~/app/components/ItemPage';
import useHash from '~/app/lib/hooks/useHash';
import { useI18n, useI18nStatic } from '~/app/lib/i18n';
import { ItemTypes, PageTypes } from '~/types';
import addUserPageApi from '~/app/lib/songwhipApi/users/addUserPageApi';
import addItemToAccountAction from '~/app/lib/store/accounts/actions/addItemToAccountAction';
import { useTracker } from '~/app/lib/tracker/useTracker';
import claimPageDialogLocales from '~/locales/fragments/claimPageDialog';

import { ALBUM_PAGE_SECTION_COMPONENTS } from './constants';
import useAlbumItemContext from './useAlbumItemContext';
import toStructuredData from './toStructuredData';
import useAlbumChecks from './useAlbumChecks';
import useUnpublishPage from './useUnpublishPage';

const debug = Debug('songwhip/ItemPageAlbum');

const OrchardLegalFooterDynamic = dynamic(
  () => import('~/app/components/ItemPage/OrchardLegalFooter'),
  { ssr: true }
);

export interface ItemPageAlbumProps
  extends Pick<ItemPageProps, 'withRedirectIfNeeded'> {
  albumId: number;
  withAlbumChecks?: boolean;
  toActionItems?: (params: {
    sections: ActionItemsSection[];
  }) => ActionItemsSection[];
  headerToolbarHeight?: string;
  renderBeforeHeader?(): JSX.Element;
}

const ItemPageAlbum: FC<ItemPageAlbumProps> = ({
  albumId,
  withAlbumChecks = true,
  toActionItems,
  headerToolbarHeight,
  renderBeforeHeader,
  ...itemPageProps
}) => {
  const { t } = useI18n();

  const { t: tClaimPageDialog } = useI18nStatic<'claimPageDialog'>(
    claimPageDialogLocales
  );

  const user = useFetchSessionUser();
  const album = useSelectAlbum(albumId)!;
  const itemContext = useAlbumItemContext({ album });
  const isLargeScreen = useIsLargeScreen();
  const { showArtistIds } = itemContext.config;
  const artists = useSelectArtists(showArtistIds);
  const artistNames = artists?.map(({ name }) => name).join(', ');
  const { setHashParam, hasHashParam, backToBeforeFirstHash } = useHash();
  const editDetailsDialogOpen = hasHashParam('editDetails');
  const dispatch = useDispatch();
  const router = useAppRouter();
  const showToast = useAppToast();
  const unpublishPage = useUnpublishPage();
  const { trackEvent } = useTracker();
  const { isLoggedIn } = useFetchSessionUser();

  if (withAlbumChecks) {
    useAlbumChecks(album);
  }

  // Try to auto-claim the album page if the user already owns
  // one of the artist on the album
  useEffect(() => {
    // We only auto-claim for Orchard users and
    // we don't want to auto-claim prerelease pages
    if (!album.isPrerelease) {
      debug('Trying to auto-claim album');

      const userAccountIds = user.userAccounts?.map(({ id }) => id);

      const userOwnsAlbum = Boolean(
        userAccountIds &&
          album.ownedByAccountIds?.find((id) => {
            return userAccountIds.includes(id);
          })
      );

      if (userOwnsAlbum) {
        debug('Noop: The user already owns the album');
        return;
      }

      const userOwnsArtist = Boolean(
        userAccountIds &&
          artists?.find((artist) => {
            return artist.ownedByAccounts?.find(({ id }) => {
              return userAccountIds.includes(id);
            });
          })
      );

      if (!userOwnsArtist) {
        debug('Noop: The user does not own any of the artist on the album');
        return;
      }

      // We call `addUserPage` with `dryRun:true` to figure out
      // if the user can claim the page, if so we add the page to
      // the user's account that's able to claim it and show a
      // success toast, otherwise we silently ignore the errors.
      // The user will still be able to try to claim the page from
      // the action menu and see the reason why they cannot claim the page.
      addUserPageApi({
        userId: user.userId!,
        itemType: ItemTypes.ALBUM,
        itemId: album.id,
        dryRun: true,
      })
        .then(({ account }) => {
          dispatch(
            addItemToAccountAction({
              accountId: account.id,
              itemType: ItemTypes.ALBUM,
              itemId: album.id,
              trackEvent,
            })
          )
            .then(() => {
              debug('Album claimed');

              showToast({
                text: tClaimPageDialog('claimPageSuccess'),
                testId: 'claimPageSuccessToast',
              });
            })
            .catch((e) => {
              debug('Error claiming album', e);
            });
        })
        .catch((e) => {
          debug('User cannot claim album', e);
        });
    }
  }, [user, album, artists]);

  return (
    <ItemPage
      key={albumId}
      pageType={album.isPrerelease ? PageTypes.PRESAVE : PageTypes.ALBUM}
      itemContext={itemContext}
      hasContent={!!album.name && !album.isShallow}
      testId="itemPageAlbum"
      error={album.error}
      isLoading={!!album.isLoading}
      isOwned={album.isOwned}
      isOrchardPage={album.isOwned}
      pageBrand={album.pageBrand}
      userCanEdit={!!album.userCanEdit}
      displayType="album"
      pageOwnedByAccountIds={album.ownedByAccountIds}
      trackerContext={useMemo(
        (): PageTrackerContext => ({
          albumId: album.id,
          albumName: album.name,
          albumPrerelease: album.isPrerelease,

          artistId: album.artistId,
          artistName: album.artistName,
        }),
        [album]
      )}
      pageSectionComponents={ALBUM_PAGE_SECTION_COMPONENTS}
      headerToolbarHeight={headerToolbarHeight}
      heroHeight={isLargeScreen ? '44vh' : '85vw'}
      backgroundHeight={isLargeScreen ? '100vh' : '105vw'}
      shareText={album.name && `"${album.name}" by ${artistNames}`}
      pagePath={album.pagePath}
      artistPagePath={album.artistPagePath}
      toActionItems={useCallback(
        ({ sections }) => {
          if (album.userCanEdit) {
            if (album.isPrerelease) {
              // Prerelease pages have an "Edit details" action that shows a dialog where
              // (non-orchard) owners can change the name, upc and releaseDate. If the page
              // is in 'live' state it can also be unpublished to revert it back to draft.
              sections[0].push({
                content: t('item.actions.editAlbumDetails'),
                testId: 'editDetails',
                icon: 'info',

                onClick: () => {
                  setHashParam({ editDetails: '' });
                },
              });

              // don't allow upgrade and unpublish of draft pages
              if (!album.isDraft) {
                sections[0].push({
                  content: t('item.actions.upgradePrerelease'),
                  href: `${album.pagePath}/release`,
                  testId: 'upgradePrerelease',
                  icon: 'publish',
                });

                sections[sections.length - 1].push({
                  testId: 'unpublishPage',
                  content: t('item.actions.unpublishPage'),
                  icon: 'unpublish',
                  onClick: () => {
                    unpublishPage({
                      albumId: album.id,
                      albumName: album.name,

                      pagePath: album.pagePath,
                    });
                  },
                });
              }
            }

            sections[0].push({
              content: t('item.actions.viewInDashboard'),
              href: `/dashboard/album/${album.id}`,
              icon: 'chart',
              testId: 'viewInDashboard',
            });

            sections[sections.length - 1].push({
              content: t('item.actions.deletePage'),
              testId: 'deletePage',
              icon: 'delete',

              onClick: async () => {
                await dispatch(
                  deleteAlbumAction({
                    albumId: album.id,
                  })
                );

                await showToast({
                  text: t('item.events.albumPageDeleted'),
                });

                router.push('/dashboard');
              },
            });
          } else if (isLoggedIn) {
            // If the user doesn't own the album,
            // they can try to claim it.
            sections[0].push({
              content: t('item.actions.claimAlbum'),
              icon: 'verified',
              testId: 'claimAlbumOption',
              onClick: () => {
                setHashParam({ claimPage: '' });
              },
            });
          }

          if (artists) {
            sections.push(
              artists.map(({ name, pagePath }) => ({
                content: name,
                href: pagePath,
                icon: 'person',
              }))
            );
          }

          if (toActionItems) {
            return toActionItems({ sections });
          }

          return sections;
        },
        [album, artists]
      )}
      renderHeroContent={useCallback(() => {
        if (isLargeScreen) {
          return (
            <ItemPageHeroContent
              title={album.name}
              subtitle={<ArtistNames artistIds={showArtistIds} />}
              padding=".8rem 0 0"
            />
          );
        }
      }, [isLargeScreen, album])}
      renderBeforeHeader={renderBeforeHeader}
      renderHeaderContent={useCallback(() => {
        if (!isLargeScreen) {
          return (
            <ItemPageHeaderContent
              title={album.name}
              subtitle={<ArtistNames artistIds={showArtistIds} />}
            />
          );
        }
      }, [album, isLargeScreen])}
      renderFooterContent={useCallback(() => {
        if (album.isOwned) {
          return <OrchardLegalFooterDynamic />;
        }
      }, [])}
      renderContent={useCallback(() => {
        return (
          <>
            <ItemPageMetadata
              error={album.error}
              pagePath={album.pagePath}
              isOwned={album.isOwned!}
              itemContext={itemContext}
              defaultImage={album.image}
              defaultTitle={
                album.name &&
                t('item.albumPageMetaTitle', {
                  albumName: album.name,
                  artistNames: artistNames ?? '?',
                })
              }
              defaultDescription={
                album.name &&
                t('item.albumPageMetaDescription', {
                  albumName: album.name,
                  artistNames: artistNames ?? '?',
                })
              }
              // REVIEW: this could be a <StructuredData> component instead
              // of having to pass props down the tree to <PageMetaData>
              // which is getting quite overloaded now
              toStructuredData={(params: any) => {
                return toStructuredData({
                  ...params,
                  albumName: album.name,
                  artistName: album.artistName,
                  artistPagePath: album.artistPagePath,
                  // TODO: add artist image (need resolving to full url)
                  artistImageUrl: undefined,
                });
              }}
            />
            {editDetailsDialogOpen && (
              <LazyComponent
                // NOTE: webpack chunk name used to target script request in cypress
                loader={() =>
                  import(
                    /* webpackChunkName: "EditDetailsDialog" */ './EditDetailsDialog'
                  )
                }
                onClose={backToBeforeFirstHash}
              />
            )}
          </>
        );
      }, [editDetailsDialogOpen, album])}
      {...itemPageProps}
    />
  );
};

export default ItemPageAlbum;
